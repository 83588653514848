import { ParticipantState } from '../../../constants/speedZoneParticipantStatus'

export const changeSpeedZoneState = async (newState: ParticipantState, result: SpeedZoneResult, zone: SpeedZoneData) => {
    const put = [
        {
            tramo_id: result.sectionId,
            brand: 'speed_zones',
            dorsal_id: result.id,
            zone_name: zone.serverInfo.zoneName,
            zone_number: zone.serverInfo.zoneNumber,
            data: JSON.stringify([zone]),
            state: newState,
            url_doc: '',
            url_playback: '',
        },
    ]

    const formBody = 'json_data=' + encodeURIComponent(JSON.stringify(put))

    const response = await fetch(
        `https://rest.anube.es/rallyrest/default/api/routes_reports/${result.rally}.json?token=${result.token}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formBody,
        }
    )

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
    }
}
