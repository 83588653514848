export function putWaypointCheck(
    waypoint: Waypoint,
    subrallyId: number,
    token: string,
    participantId: number,
    ut: number,
    sn: number | null = null
) {
    return fetch('https://rest.anube.es/rallyrest/default/api/waypoint_checks/' + subrallyId + '.json?token=' + token, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'json_data=' + JSON.stringify([{ ut: ut, wp_id: waypoint.id, dorsal_id: participantId, sn: sn }]),
    })
}