
/**
 * Function to group ranges with the same params for optimize the rangerbar width
 * WARNING! It has to be a copy of the array so that it is not the same memory point, otherwise the original would be modified.
 * @param ranges Ranges to gather
 * @returns The ranges grouped
 */
export function groupRanges(ranges: RangeBar[]) {
    if (ranges.length === 0) {
        return [] as RangeBar[]
    }

    const groupedRanges: RangeBar[] = []
    let currentGroup = { ...ranges[0] }

    for (let i = 1; i < ranges.length; i++) {
        const nextRange = ranges[i]
        if (currentGroup.edges) {
            if (
                currentGroup.edges[1] === nextRange.edges[0] &&
                currentGroup.disabled === nextRange.disabled &&
                currentGroup.matchedGps === nextRange.matchedGps &&
                currentGroup.matched === nextRange.matched &&
                currentGroup.firstUnfinished === nextRange.firstUnfinished
            ) {
                currentGroup = { ...currentGroup, edges: [currentGroup.edges[0], nextRange.edges[1]] };
            } else {
                groupedRanges.push(currentGroup)
                currentGroup = { ...nextRange }
            }
        }
    }
    groupedRanges.push(currentGroup)
    return groupedRanges
}

export function rangeToRelative(range: RangeBar, totalWidth: number) {
    let width = ((range.edges[1] - range.edges[0]) / totalWidth) * 100
    let start = (range.edges[0] / totalWidth) * 100

    // // avoid thin ranges
    if (width < 1) {
        width = 1
    }

    const relativeRangeBar: RelativeRangeBar = {
        width,
        start,
        ...range,
    }
    return relativeRangeBar
}
