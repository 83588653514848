import * as XLSX from 'xlsx'
import { timeUtcToHHMMSS } from '../../utils'
import type { ExcelData, SpeedExcelRow, WaypointsExcelRow } from '../../types/excel'

export const waypointExcelData = (info: WaypointsResult[]) => {
    var waypointExcel: WaypointsExcelRow[] = []

    let WpCheckedPassed: any[] = []
    let WpCheckedMissed: any[] = []
    let UnfinishedWpCheckedPassed: any[] = []
    let UnfinishedWpCheckedMissed: any[] = []
    for (const result of info) {
        if (result.data.waypoints.length > 0) {
            for (let areaPoint of result.areaPoints) {
                if (!areaPoint.unfinished) {
                    if (areaPoint.matchedGps) {
                        WpCheckedPassed.push(areaPoint.name)
                    } else if (areaPoint.blocked) {
                        WpCheckedMissed.push(areaPoint.name)
                    }
                } else {
                    if (areaPoint.matchedGps) {
                        UnfinishedWpCheckedPassed.push(areaPoint.name)
                    } else if (areaPoint.blocked) {
                        UnfinishedWpCheckedMissed.push(areaPoint.name)
                    }
                }
            }
            waypointExcel.push({
                Participant: result.number,
                'Passed Count': WpCheckedPassed.length,
                'Passed Name': WpCheckedPassed.toString(),
                'Missed Count': WpCheckedMissed.length,
                'Missed Name': WpCheckedMissed.toString(),
                'Unfinished Passed Count': UnfinishedWpCheckedPassed.length,
                'Unfinished Passed Name': UnfinishedWpCheckedPassed.toString(),
                'Unfinished Missed Count': UnfinishedWpCheckedMissed.length,
                'Unfinished Missed Name': UnfinishedWpCheckedMissed.toString(),
            })

            WpCheckedPassed = []
            WpCheckedMissed = []
            UnfinishedWpCheckedPassed = []
            UnfinishedWpCheckedMissed = []
        }
    }

    const waypointWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(waypointExcel)

    const excelData: ExcelData = {
        worksheet: waypointWorksheet,
        sheetName: 'Waypoints Report',
    }

    return [excelData]
}
