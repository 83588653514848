import { calculatePenalization, getFirstIndexInArea, getLastIndexInArea } from '../../outtrack/common'
import { byNumber } from '../../sortingfunctions'
import { getClosestEntryIndex, getClosestExitIndex } from '../customZoneAnalyzer/customZoneFunctions'
import { haversineDistance } from '../../utils'

export const sortByFinishTime = (results: SpeedZoneResult[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) =>
        byFinishTime({ number: a.number, zones: a.data.zones }, { number: b.number, zones: b.data.zones })
    )
    return ascending ? sortedResults : sortedResults.reverse()
}

export const byFinishTime = (zonesA: SortingSpeed, zonesB: SortingSpeed) => {
    if (
        zonesA.zones &&
        zonesB.zones &&
        zonesA.zones.length > 0 &&
        zonesB.zones.length > 0 &&
        zonesA.zones[zonesA.zones.length - 1][0].analysisInfo.exitInfo &&
        zonesB.zones[zonesB.zones.length - 1][0].analysisInfo.exitInfo
    ) {
        const a = zonesA.zones[zonesA.zones.length - 1][0].analysisInfo?.exitInfo?.point[2] || null
        const b = zonesB.zones[zonesB.zones.length - 1][0].analysisInfo?.exitInfo?.point[2] || null

        if (a !== null && b !== null) {
            return a > b ? 1 : -1
        } else if (a !== null && b === null) {
            return -1
        } else if (a === null && b !== null) {
            return 1
        }
    }

    if (zonesA.zones.length > 0 && zonesB.zones.length < 1) {
        return 1
    } else if (zonesA.zones.length < 1 && zonesB.zones.length > 0) {
        return -1
    }
    return byNumber(zonesA.number, zonesB.number)
}

export function getAverageSpeed(fromIndex: number, toIndex: number, track: number[][]) {
    let speedAverage = 0
    for (let i = fromIndex; i <= toIndex; i++) {
        speedAverage += track[i][3]
    }

    speedAverage /= toIndex - fromIndex + 1
    return Math.floor(speedAverage)
}

function findexClosestIndexToACertainDistance(
    areaPoint: SpeedZone,
    index: number,
    participantTrack: number[][],
    toleranceRadiusNumber: number
) {
    const d = haversineDistance(participantTrack[index], [areaPoint.longitude, areaPoint.latitude])
    if (toleranceRadiusNumber > d) {
        let index2 = index + 1
        const maxIndex = participantTrack.length
        while (index2 < maxIndex) {
            const d2 = haversineDistance(participantTrack[index2], [areaPoint.longitude, areaPoint.latitude])
            if (toleranceRadiusNumber < d2) {
                return index2
            }
            index2++
        }
    }

    return index
}

function getSpeedZoneIndexes(
    speedZone: InitEndZone,
    participantTrack: number[][],
    threshold: number,
    toleranceRadius: boolean,
    consecSecs: number,
    maxSpeed: number,
    penaltyTolerance: number,
    criteria: CriteriaAPI,
    speedLimit: number,
    toleranceRadiusNumber: number | null
): SpeedInfo[] {
    let currentIndex = 0
    let speeds: SpeedInfo[] = []

    while (true) {
        let startIndex: number | null = null
        let endIndex: number | null = null
        let fixStartIndex: number = 0
        let fixEndIndex: number = 0

        if (toleranceRadius) {
            startIndex = getLastIndexInArea(
                speedZone.init.longitude,
                speedZone.init.latitude,
                90,
                participantTrack,
                currentIndex,
                null
            )

            // Pick the first point outside area
            if (startIndex !== null) {
                let newIndex = Math.min(startIndex + 1, participantTrack.length)
                fixStartIndex = newIndex - startIndex
                startIndex = newIndex
            }
        } else {
            if (toleranceRadiusNumber) {
                startIndex = findexClosestIndexToACertainDistance(
                    speedZone.init,
                    getClosestExitIndex(speedZone.init, participantTrack, currentIndex),
                    participantTrack,
                    toleranceRadiusNumber
                )
            } else {
                startIndex = getClosestExitIndex(speedZone.init, participantTrack, currentIndex)
            }
        }

        if (toleranceRadius) {
            endIndex = getFirstIndexInArea(
                speedZone.end.longitude,
                speedZone.end.latitude,
                90,
                participantTrack,
                currentIndex,
                null
            )
            // Pick the previous point outside area
            if (endIndex !== null) {
                const newIndex = Math.max(endIndex - 1, 0)
                fixEndIndex = endIndex - newIndex
                endIndex = newIndex
            }
        } else {
            if (toleranceRadiusNumber) {
                endIndex = findexClosestIndexToACertainDistance(
                    speedZone.end,
                    getClosestEntryIndex(speedZone.end, participantTrack, currentIndex),
                    participantTrack,
                    toleranceRadiusNumber
                )
            } else {
                endIndex = getClosestEntryIndex(speedZone.end, participantTrack, currentIndex)
            }
        }

        // If both indices are null, we are done
        if (startIndex === null && endIndex === null) {
            if (speeds.length === 0) {
                const entryExitInfo = getEntryExitInfo(
                    [startIndex, endIndex],
                    participantTrack,
                    consecSecs,
                    maxSpeed,
                    -1,
                    penaltyTolerance,
                    criteria,
                    speedLimit
                )
                speeds.push(entryExitInfo)
            }
            
            // We break the loop here
            break
        }

        const slice = [startIndex, endIndex]
        const entryExitInfo = getEntryExitInfo(
            slice,
            participantTrack,
            consecSecs,
            maxSpeed,
            -1,
            penaltyTolerance,
            criteria,
            speedLimit
        )
        speeds.push(entryExitInfo)

        // Undo the increase or decrease when we pick previous point in case if is necessary
        startIndex! -= fixStartIndex
        endIndex! += fixEndIndex

        const lastLapAreaIndex = Math.max(startIndex!, endIndex!)
        const lastLapArea = lastLapAreaIndex === startIndex ? speedZone.init : speedZone.end

        // Update currentIndex to the next starting point
        currentIndex =
            Number(
                getLastIndexInArea(
                    lastLapArea.longitude,
                    lastLapArea.latitude,
                    90,
                    participantTrack,
                    lastLapAreaIndex,
                    null
                )
            ) + 1
    }

    return speeds
}

export function chooseMostFavorableEntryExitInfo(
    speedZone: InitEndZone,
    participantTrackA: number[][],
    participantTrackB: number[][],
    threshold: number,
    consecSecs: number,
    maxSpeed: number,
    speedLimit: number,
    criteria: CriteriaAPI,
    penaltyTolerance: number,
    toleranceRadius: boolean,
    toleranceRadiusNumber: number | null
) {
    let trackSliceA: SpeedInfo[] = getSpeedZoneIndexes(
        speedZone,
        participantTrackA,
        threshold,
        toleranceRadius,
        consecSecs,
        maxSpeed,
        penaltyTolerance,
        criteria,
        speedLimit,
        toleranceRadiusNumber
    )
    const trackAInfo: TrackSpeed = {
        participantTrackType: 'primary',
        speedInfo: trackSliceA,
    }

    let trackSliceB: SpeedInfo[] = getSpeedZoneIndexes(
        speedZone,
        participantTrackB,
        threshold,
        toleranceRadius,
        consecSecs,
        maxSpeed,
        penaltyTolerance,
        criteria,
        speedLimit,
        toleranceRadiusNumber
    )
    const trackBInfo: TrackSpeed = {
        participantTrackType: 'secondary',
        speedInfo: trackSliceB,
    }
    const infringementsA = trackAInfo.speedInfo.filter(e => e.infringements.length > 0)
    const infringementsB = trackBInfo.speedInfo.filter(e => e.infringements.length > 0)

    if (infringementsA.length === infringementsB.length && infringementsA.length > 0 && infringementsB.length > 0) {
        return infringementsA[0].infringements.length <= infringementsB[0].infringements.length
            ? trackAInfo
            : trackBInfo
    }

    return infringementsA.length >= infringementsB.length ? trackAInfo : trackBInfo

    // if (!trackAInfo.oneIndex.speedInfo.reached) {
    //     return trackAInfo
    // }

    // if (
    //     (trackAInfo.oneIndex.speedInfo.fastestRange !== null) !==
    //     (trackBInfo.oneIndex.speedInfo.fastestRange !== null)
    // ) {
    //     return trackAInfo.oneIndex.speedInfo.fastestRange === null ? trackAInfo : trackBInfo
    // }

    // if (trackAInfo.oneIndex.speedInfo.fastestRange !== null && trackBInfo.oneIndex.speedInfo.fastestRange !== null) {
    //     const speedA = trackAInfo.oneIndex.speedInfo.fastestRange.fastestPointInfo.point[3]
    //     const speedB = trackBInfo.oneIndex.speedInfo.fastestRange.fastestPointInfo.point[3]

    //     return speedA < speedB ? trackAInfo : trackBInfo
    // }

    // if (
    //     (trackAInfo.oneIndex.speedInfo.singleFastestInfo !== null) !==
    //     (trackBInfo.oneIndex.speedInfo.singleFastestInfo !== null)
    // ) {
    //     return trackAInfo.oneIndex.speedInfo.singleFastestInfo === null ? trackBInfo : trackAInfo
    // }

    // if (!trackAInfo.oneIndex.speedInfo.singleFastestInfo && trackBInfo.oneIndex.speedInfo.singleFastestInfo) {
    //     return trackBInfo
    // }

    // const speedA = trackAInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
    //     ? trackAInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
    //     : 0
    // const speedB = trackBInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
    //     ? trackBInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
    //     : 0
    // return speedA < speedB ? trackAInfo : trackBInfo
}

function calculateInfringements(
    startIndex: number,
    endIndex: number,
    maxSpeed: number,
    consecSecs: number,
    criteria: CriteriaAPI | null = null,
    participantTrack: number[][],
    tolerance: number,
    speedLimit: number
): SpeedInfringements[] {
    const getInfringement = (startIndex: number, endIndex: number, cont: number) => {
        return getInfringementDetails(startIndex, endIndex, cont, criteria, participantTrack, tolerance, speedLimit)
    }

    let cont: number = 0
    const infringements: SpeedInfringements[] = []
    let firstTimeInfringement: number = 0
    for (let index = startIndex; index <= endIndex; index++) {
        const currentPoint = participantTrack[index]

        // If the current speed exceeds maxSpeed
        if (currentPoint[3] > maxSpeed) {
            if (cont === 0) firstTimeInfringement = index
            cont++

            // If the last point exceeds maxSpeed, calculate the infringement
            if (index === endIndex && cont >= consecSecs) {
                infringements.push(getInfringement(firstTimeInfringement, index, cont))
            }
        } else {
            if (cont >= consecSecs) {
                infringements.push(getInfringement(firstTimeInfringement, index - 1, cont))
            }

            firstTimeInfringement = 0 // Reset first infringement
            cont = 0 // Reset counter
        }
    }
    return infringements
}
function getInfringementDetails(
    startIndex: number,
    endIndex: number,
    cont: number,
    criteria: CriteriaAPI | null = null,
    participantTrack: number[][],
    tolerance: number,
    speedLimit: number
): SpeedInfringements {
    let maxSpeed = -1
    let maxSpeedCoordinates: any = []
    let criteriaInfringement: CriteriaInfringement = { money: 0, penalty: 0, numExcessMax: 0 }

    if (criteria) {
        for (let i = startIndex; i <= endIndex; i++) {
            const value = participantTrack[i][3]
            if (value > maxSpeed) {
                maxSpeed = value
                maxSpeedCoordinates = [participantTrack[i][0], participantTrack[i][1]]
            }
            const penal = calculatePenalization(
                criteria,
                tolerance,
                speedLimit,
                participantTrack[i],
                criteriaInfringement.numExcessMax
            )
            criteriaInfringement.numExcessMax += penal.numExcessMax
            criteriaInfringement.penalty += penal.penalty
            criteriaInfringement.money += penal.money
        }
    }

    return {
        firstInfringementIndex: startIndex,
        firstPointParticipant: participantTrack[startIndex],
        lastInfringementIndex: endIndex,
        lastPointParticipant: participantTrack[endIndex],
        maxSpeed,
        criteriaInfringement,
        maxSpeedCoordinates,
        timeInfringementCount: cont,
    }
}

function findExtremeInfo(
    start: number,
    end: number,
    comparator: (a: number, b: number) => boolean,
    participantTrack: number[][]
): SingleInfo | null {
    let extremeInfo: SingleInfo | null = null
    for (let i = start; i <= end; i++) {
        const currPoint = participantTrack[i]
        if (!extremeInfo || comparator(currPoint[3], extremeInfo.point[3])) {
            let duration = i > 0 ? currPoint[2] - participantTrack[i - 1][2] : 1
            extremeInfo = { index: i, point: currPoint, duration }
        }
    }
    return extremeInfo
}

export function getEntryExitInfo(
    trackSlice: (number | null)[],
    participantTrack: number[][],
    consecSecs: number,
    maxSpeed: number,
    minSpeed: number,
    tolerance: number,
    criteria: CriteriaAPI | null = null,
    speedLimit: number = 0
): SpeedInfo {
    const [startIndex, endIndex] = trackSlice

    // Return default if startIndex or endIndex is null
    if (startIndex === null || endIndex === null) {
        return {
            reached: false,
            overlapping: false,
            entryInfo: null,
            exitInfo: null,
            singleFastestInfo: null,
            fastestRange: null,
            singleSlowestInfo: null,
            infringements: [],
        }
    }

    const isOverlapping = startIndex > endIndex
    let fastestRange = null
    let entryInfo = { index: startIndex, point: participantTrack[startIndex] }
    let exitInfo = { index: endIndex, point: participantTrack[endIndex] }
    let singleFastestInfo = null
    let singleSlowestInfo = null
    let infringements: SpeedInfringements[] = []

    // Check if both startIndex and endIndex are valid
    if (!isOverlapping) {
        // Calculate fastestRange if valid and not overlapping
        fastestRange = getFastestRange(startIndex, endIndex, participantTrack, consecSecs, maxSpeed, minSpeed)

        // Calculate infringements based on speed limits
        infringements = calculateInfringements(
            startIndex,
            endIndex,
            maxSpeed,
            consecSecs,
            criteria,
            participantTrack,
            tolerance,
            speedLimit
        )

        // Calculate fastest and slowest info
        singleFastestInfo = findExtremeInfo(startIndex, endIndex, (a, b) => a > b, participantTrack)
        singleSlowestInfo = findExtremeInfo(startIndex, endIndex, (a, b) => a < b, participantTrack)
    }

    return {
        reached: true,
        overlapping: isOverlapping,
        entryInfo,
        exitInfo,
        singleFastestInfo,
        fastestRange,
        singleSlowestInfo,
        infringements,
    }
}

const DURATION_TIMEOUT = 5

function getFastestRange(
    fromIndex: number,
    toIndex: number,
    participantTrack: number[][],
    consecSecs: number,
    maxSpeed: number,
    minSpeed: number
) {
    let currentRange = null
    let fastestRange = null
    let slowestRange = null
    if (isNaN(maxSpeed) || maxSpeed === 0) return fastestRange
    for (let i = fromIndex; i <= toIndex; i++) {
        let duration = i === 0 ? 1 : participantTrack[i][2] - participantTrack[i - 1][2]
        const currentSpeed = participantTrack[i][3]
        if (!isNaN(maxSpeed)) {
            if (currentSpeed > maxSpeed) {
                if (currentRange === null || duration >= DURATION_TIMEOUT) {
                    fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)

                    currentRange = {
                        duration: duration >= DURATION_TIMEOUT ? 1 : duration,
                        fastestPointInfo: {
                            index: i,
                            point: participantTrack[i],
                        },
                    }
                } else {
                    currentRange.duration += duration
                    if (currentRange.fastestPointInfo.point[3] < currentSpeed) {
                        currentRange.fastestPointInfo = {
                            index: i,
                            point: participantTrack[i],
                        }
                    }
                }
            } else if (currentRange !== null) {
                fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)
                currentRange = null
            }
        } else {
            if (currentSpeed < minSpeed) {
                if (currentRange === null || duration >= DURATION_TIMEOUT) {
                    slowestRange = chooseNewSpeedRange(currentRange, slowestRange, consecSecs)

                    currentRange = {
                        duration: duration >= DURATION_TIMEOUT ? 1 : duration,
                        fastestPointInfo: {
                            index: i,
                            point: participantTrack[i],
                        },
                    }
                } else {
                    currentRange.duration += duration
                    if (currentRange.fastestPointInfo.point[3] < currentSpeed) {
                        currentRange.fastestPointInfo = {
                            index: i,
                            point: participantTrack[i],
                        }
                    }
                }
            } else {
                if (currentRange !== null && !isNaN(maxSpeed)) {
                    slowestRange = chooseNewSpeedRange(currentRange, slowestRange, consecSecs)
                    currentRange = null
                }
            }
        }
    }

    fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)

    return fastestRange
}

function chooseNewSpeedRange(currentRange: FastestRange | null, fastestRange: FastestRange | null, consecSecs: number) {
    if (currentRange === null) {
        return fastestRange
    }

    if (currentRange.duration < consecSecs) {
        return fastestRange
    }

    if (fastestRange !== null && currentRange.fastestPointInfo.point[3] <= fastestRange.fastestPointInfo.point[3]) {
        return fastestRange
    }

    return currentRange
}

export function waypointsToSpeedZones(waypoints: SpeedZoneAPI[]) {
    waypoints = waypoints.filter(e => e.type === 'DZ' || e.type === 'FZ')

    const res: InitEndZone[] = []
    waypoints
        .filter(e => e.type === 'DZ')
        .forEach(dzWaypoint => {
            const fzWaypoint = waypoints.find(e => e.type === 'FZ' && e.number === dzWaypoint.number)
            if (fzWaypoint !== undefined) {
                const init: SpeedZone = {
                    ...dzWaypoint,
                    radius: 90,
                }
                const end: SpeedZone = {
                    ...fzWaypoint,
                    radius: 90,
                }

                res.push({
                    id: `${init.id}-${end.id}`,
                    init: init,
                    end: end,
                })
            }
        })

    return res
}

export function createAnalysisOfSpeedZones(
    speedZones: any[],
    params: ParamsAnalyzerSpeedZone,
    mainParticipantTrack: number[][],
    secondaryParticipantTrack: number[][],
    state: number
) {
    return speedZones.map(speedZone => {
        const maxUnpenalizableSpeed = speedZone.init.speed! + params.penaltyTolerance
        const infos = chooseMostFavorableEntryExitInfo(
            speedZone,
            mainParticipantTrack,
            secondaryParticipantTrack,
            params.threshold,
            params.consecSecs,
            maxUnpenalizableSpeed,
            speedZone.init.speed!,
            params.speedZoneCriteria,
            params.penaltyTolerance,
            params.toleranceRadius,
            params.toleranceRadiusNumber
        )
        let totalMoney = 0
        let totalPenalty = 0
        infos.speedInfo.forEach(e => {
            totalMoney += e.infringements.reduce((a, b) => a + b.criteriaInfringement.money, 0)
            totalPenalty += e.infringements.reduce((a, b) => a + b.criteriaInfringement.penalty, 0)
        })
        const zones = infos.speedInfo.map(info => {
            const maxSpeed = speedZone.init.speed!
            const cardSpeedPoint =
                info.fastestRange !== null
                    ? info.fastestRange.fastestPointInfo.point
                    : info.singleFastestInfo !== null
                      ? info.singleFastestInfo.point
                      : null
            const cardExcess = cardSpeedPoint !== null ? Math.max(0, cardSpeedPoint[3] - maxSpeed) : 0
            const penalization = 1

            const speedZoneData: SpeedZoneData = {
                serverInfo: {
                    zoneNumber: speedZone.init.number,
                    zoneName: speedZone.init.name,
                    maxSpeed,
                    areaPoints: {
                        init: speedZone.init,
                        end: speedZone.end,
                    },
                },
                analysisInfo: {
                    id: `${speedZone.init.id}-${speedZone.end.id}`,
                    participantTrackType: infos.participantTrackType,
                    reached: info.reached,
                    overlapping: info.overlapping,
                    entryInfo: info.entryInfo,
                    exitInfo: info.exitInfo,
                    singleFastestInfo: info.singleFastestInfo,
                    fastestRange: info.fastestRange,
                    infringements: info.infringements,
                    link: `https://anubesport.com/tracking/?rally=rally${params.subrallyId}&port=auto&token=${params.token}&map=satellite&type=animation&participants=${params.participantUINumber}&participant_ids=${params.participant.id}&from=${info.entryInfo?.point[2]}&to=${info.exitInfo?.point[2]}`,
                    penalization,
                    cardSpeedPoint,
                    cardExcess,
                    maxUnpenalizableSpeed,
                },
                state: state,
                params: {
                    toleranceRadius: params.toleranceRadius,
                    penaltyTolerance: params.penaltyTolerance,
                    consecSecs: params.consecSecs,
                    threshold: params.threshold,
                    speedZoneCriteria: params.speedZoneCriteria,
                    toleranceRadiusNumber: params.toleranceRadiusNumber,
                },
                sandbox: params.modeSandbox,
                totalMoney,
                totalPenalty,
            }

            return speedZoneData
        })
        return zones
    })
}
