import React, { memo, useEffect, useMemo } from 'react'
import { groupRanges, rangeToRelative } from './rangeBarUtils'

import './range-bar.css'
import { finishState } from '../../analyzer/waypointsAnalyzer/waypointsFunctions'

interface Props {
    inactiveStart: number
    theme: string
    ranges: RangeBar[]
    width: number
    state?: string
    type?: 'waypoints-report' | 'other'
}

const RangeBar = ({ ranges, inactiveStart, width, theme, state, type = 'other' }: Props) => {
    const getRangeColor = (relativeRangeBar: RelativeRangeBar) => {
        const { disabled, firstUnfinished, matchedGps, blocked, matched } = relativeRangeBar
        if (disabled) {
            return 'inner-bar-inactive'
        }

        if (firstUnfinished) {
            return 'inner-bar-finish'
        }

        if (matchedGps) {
            return 'inner-bar-matchedGps'
        }

        if (matched && blocked === true) {
           return 'inner-bar-active'
        }
        if (matched && blocked === false )  {
            return 'inner-bar-matched'
        }
        
        if (type === 'waypoints-report'  && blocked === false) {
            return 'bg-red-400'
        }

        return 'inner-bar-active'
    }
    const computedRanges: RelativeRangeBar[] = groupRanges(ranges)
    .filter(range => range.edges)
    .map((range: RangeBar) => rangeToRelative(range, width))
    const endingZone = useMemo(() => {
        if (state && (state === finishState.DNF || state === finishState.DNS)) {
            return (
                <div
                    className="inner-bar-dns-dnf absolute bottom-0 h-full"
                    style={{ width: `${100 - inactiveStart}%`, marginLeft: `${inactiveStart}%` }}
                ></div>
            )
        }
        return (
            <div
                className="inner-bar-end absolute bottom-0 h-full"
                style={{ width: `${100 - inactiveStart}%`, marginLeft: `${inactiveStart}%` }}
            ></div>
        )
    }, [inactiveStart, state])

    return (
        <div className="orderedRangeBar mr-1 self-center">
            <div className={`ranges ranges-theme-${theme}`}>
                {computedRanges.map((range, index) => (
                    <>
                        <div
                            key={index}
                            className={`${getRangeColor(range)} absolute bottom-0 h-full`}
                            style={{ width: `${range.width}%`, marginLeft: `${range.start}%` }}
                        ></div>
                    </>
                ))}
                <div>{endingZone}</div>
            </div>
        </div>
    )
}

export default React.memo(RangeBar)
