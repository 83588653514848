import { FaMapMarkerAlt } from 'react-icons/fa'
import { useState } from 'react'
import { Button } from 'antd'
import { putWaypointCheck } from '../../../../lib/reports/waypoints/putWaypointsCheck'

interface Props {
    waypoints: Waypoint[]
    openPopup: any
    subrallyId: number
    token: string
    mainSerialNumber: number
    participantId: number
}

const WaypointsTable = ({ waypoints, mainSerialNumber, openPopup, subrallyId, token, participantId }: Props) => {
    const [waypointsTable, setWaypointsTable] = useState<Waypoint[]>(waypoints)
    const removeWaypoint = (waypointId: number) => {
        const updatedWaypoints = waypointsTable.filter(wp => wp.id !== waypointId)
        setWaypointsTable(updatedWaypoints)
    }
    if (waypointsTable.length < 1) {
        return (
            <div className={`text-right] m-1 flex flex-row justify-center text-nowrap rounded font-semibold`}>
                Empty Table
            </div>
        )
    }
    return (
        <>
            {waypointsTable.map((waypoint, index) => {
                return (
                    <div className={`flex flex-row justify-between rounded px-1 py-[1px]`} key={index}>
                        <div className="w-16">
                            <Button
                                type="text"
                                className="h-6 w-full text-ellipsis rounded-lg border border-solid border-neutral-300 bg-neutral-100 px-3 text-center font-bold"
                                onClick={() => openPopup(waypoint.name)}
                            >
                                {waypoint.name}
                            </Button>
                        </div>
                        <div className="w-min">
                            <Button
                                target="_blank"
                                rel="noreferrer"
                                className="h-5 w-min text-center"
                                href={waypoint.links?.['waypointsReport']}
                                type="link"
                            >
                                Link
                            </Button>
                        </div>

                        <div className="flex space-x-1">
                            <button
                                className="flex w-min items-center justify-center rounded-lg border border-solid border-neutral-300 px-2"
                                onClick={event => {
                                    event.stopPropagation()
                                    const ut = waypoint.timeMatched ? waypoint.timeMatched : 1
                                    putWaypointCheck(
                                        waypoint,
                                        subrallyId,
                                        token,
                                        participantId,
                                        ut,
                                        mainSerialNumber
                                    )
                                        .then(value => {
                                            removeWaypoint(waypoint.id)
                                        })
                                        .catch(error => {
                                            console.error('Error:', error)
                                        })
                                }}
                            >
                                <FaMapMarkerAlt className="text-[green] hover:cursor-pointer" />
                            </button>
                            <button
                                className="flex w-min items-center justify-center rounded-lg border border-solid border-neutral-300 px-2"
                                onClick={event => {
                                    event.stopPropagation()
                                    putWaypointCheck(waypoint, subrallyId, token, participantId, 0, mainSerialNumber)
                                        .then(value => {
                                            removeWaypoint(waypoint.id)
                                        })
                                        .catch(error => {
                                            console.error('Error:', error)
                                        })
                                }}
                            >
                                <FaMapMarkerAlt className="text-[red] hover:cursor-pointer" />
                            </button>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default WaypointsTable
