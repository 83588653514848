import { Button, Popover } from 'antd'
import { finishState } from '../../../../analyzer/waypointsAnalyzer/waypointsFunctions'
import { putWaypointCheck } from '../../../../lib/reports/waypoints/putWaypointsCheck'

type Props = {
    dnsDnf: string
    wpDSS: any
    wpASS: any
    updateState: (state: string) => void
    subrallyId : number,
    token : string,
    participantId : number
    mainSerialNumber : number
}
const DnsDnf = ({ dnsDnf, wpASS, wpDSS, updateState ,participantId,subrallyId,token,mainSerialNumber}: Props) => {
    const waypointsReport = () => {
        if (dnsDnf === finishState.DNS) {
            const content = wpDSS?.name
            return (
                <Popover content={content} trigger="hover">
                    <div className="mr-2 h-full w-14 bg-red-anube text-center text-xs font-bold text-black">
                        {dnsDnf}
                    </div>
                </Popover>
            )
        } else if (dnsDnf === finishState.DNF) {
            const content = wpASS?.name
            return (
                <>
                    <Popover content={content} trigger="hover">
                        <div className="mr-2 h-full w-14 bg-black text-center text-xs font-bold text-red-anube">
                            {dnsDnf}
                        </div>
                    </Popover>
                </>
            )
        }

        if (wpDSS && !wpDSS.matched && !wpDSS.matchedGps) {
            return (
                <div
                    className="m-1 w-14 rounded bg-neutral-200 hover:cursor-pointer hover:font-bold"
                    onClick={() => {
                        putWaypointCheck(wpDSS, subrallyId, token, participantId, 0,mainSerialNumber)
                        updateState(finishState.DNS)
                    }}
                >
                    Set DNS
                </div>
            )
        }
        if (wpASS && !wpASS.matched && !wpASS.matchedGps) {
            return (
                <div
                    className="m-1 w-14 rounded bg-neutral-200 hover:cursor-pointer hover:font-bold"
                    onClick={() => {
                        putWaypointCheck(wpASS, subrallyId, token, participantId, 0,mainSerialNumber)

                        updateState(finishState.DNF)
                    }}
                >
                    Set DNF
                </div>
            )
        }
        return <></>
    }

    return waypointsReport()
}
export default DnsDnf
